import React, { useState } from 'react';
import { Box, Heading, Flex } from '@chakra-ui/react';
import ListProducts from '../components/inventario/listProducts';
import AddProduct from '../components/inventario/addProduct';
import SearchProducts from '../components/inventario/searchProduct'; // Importar el buscador

export default function InventarioPage() {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (term) => {
    setSearchTerm(term); // Actualiza el término de búsqueda
  };

  return (
    <Box p={6}>
      <Flex justify="space-between">
        <Box flex="1" p={6} bg="white" boxShadow="lg" borderRadius="md" maxW="60%" mx="auto">
          <Flex justify="space-between" alignItems="center" mb={4}>
            <Heading as="h2" size="lg">
              Inventario
            </Heading>
            {/* Colocar el componente de búsqueda */}
            <SearchProducts onSearch={handleSearch} />
          </Flex>

          <ListProducts searchTerm={searchTerm} /> {/* Pasar el término de búsqueda */}
        </Box>

        <Box
          p={4}
          bg="white"
          boxShadow="lg"
          borderRadius="md"
          borderWidth="1px"
          borderColor="gray.200"
          ml={6}
          maxW="auto"
          maxH="80px"
        >
          <AddProduct />
        </Box>
      </Flex>
    </Box>
  );
}