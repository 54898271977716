import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Avatar,
  HStack,
  Button,
  Menu,
  MenuButton,
  MenuList,
  Text,
  MenuItem,
  MenuDivider,
  useDisclosure,
  IconButton,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  DrawerHeader,
  DrawerBody,
  VStack,
  CloseButton,
  Link,
} from '@chakra-ui/react';
import { AddIcon, HamburgerIcon, ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { FaCog, FaUserSecret } from "react-icons/fa";
import { IoMdExit } from "react-icons/io";
import { getWithAuth, requestWithAuth, routes } from '../../utils/apiRoutes';
import { useNavigate } from 'react-router-dom';
import Confetti from 'react-confetti';

const Links = ['', '', ''];

const NavLink = (props) => {
  const { children } = props;
  return (
    <Box
      as="a"
      px={2}
      py={1}
      rounded={'md'}
      _hover={{
        textDecoration: 'none',
        bg: 'gray.200',
      }}
      href={'#'}
    >
      {children}
    </Box>
  );
};

export default function WithAction({ children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [userProfileImage, setUserProfileImage] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [showSubnavbar, setShowSubnavbar] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const data = await getWithAuth(routes.userInfo);
        if (data) {
          if (data.imageprofile) {
            setUserProfileImage(data.imageprofile);
          }
          if (data.rank === 'admin') {
            setIsAdmin(true);
          }
        }
      } catch {}
    };

    fetchUserInfo();
  }, []);

  const handleLogout = async () => {
    try {
      const response = await requestWithAuth(routes.exitSession);
      if (response.message === 'Sesión cerrada exitosamente') {
        localStorage.removeItem('token');
        setTimeout(() => {
          navigate('/login');
        }, 500);
      }
    } catch {}
  };

  const handleConfig = () => {
    navigate('/user/config');
  };

  const handleHK = () => {
    navigate('/admin/index');
  };

  const handleCloseSubnavbar = () => {
    setShowSubnavbar(false);
    const expireTime = new Date().getTime() + 60 * 60 * 1000;
    localStorage.setItem('subnavbarHiddenUntil', expireTime);
  };

  useEffect(() => {
    const expireTime = localStorage.getItem('subnavbarHiddenUntil');
    if (expireTime && new Date().getTime() < expireTime) {
      setShowSubnavbar(false);
    }
  }, []);

  return (
    <>
      <Box bg="white" px={4} position="fixed" width="100%" top="0" zIndex="900" boxShadow="md">
        <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
          <IconButton
            size={'md'}
            icon={<HamburgerIcon />}
            aria-label={'Open Menu'}
            display={{ md: 'none' }}
            onClick={onOpen}
          />

          <HStack as={'nav'} spacing={4} justify="center" flex="1" display={{ base: 'none', md: 'flex' }}>
            {Links.map((link, index) => (
              <NavLink key={`nav-link-${index}`}>{link}</NavLink>
            ))}
          </HStack>

          <Flex alignItems={'center'} ml="auto">
            <Button variant={'solid'} colorScheme={'gray'} size={'sm'} mr={4} leftIcon={<AddIcon />}>
              Acceso directo editable
            </Button>
            <Menu>
              {({ isOpen }) => (
                <>
                  <MenuButton
                    as={Button}
                    rounded={'full'}
                    variant={'link'}
                    cursor={'pointer'}
                    minW={0}
                    rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                  >
                    <Avatar size={'sm'} src={userProfileImage || undefined} />
                  </MenuButton>
                  <MenuList>
                    <MenuItem icon={<FaCog />} onClick={handleConfig}>
                      Configuración
                    </MenuItem>
                    {isAdmin && (
                      <MenuItem icon={<FaUserSecret />} onClick={handleHK}>
                        Housekeeping
                      </MenuItem>
                    )}
                    <MenuDivider />
                    <MenuItem icon={<IoMdExit />} onClick={handleLogout}>
                      Cerrar sesión
                    </MenuItem>
                  </MenuList>
                </>
              )}
            </Menu>
          </Flex>
        </Flex>
      </Box>

      {showSubnavbar && (
        <Box
          bg="linear-gradient(to right, rgba(56, 178, 172, 0.7), rgba(0, 123, 255, 0.7))"
          px={4}
          py={2}
          position="fixed"
          width="100%"
          top="64px"
          zIndex="2"
          color="white"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          fontSize="md"
        >
          <Link 
            href="https://gopymes.vefixy.com/" 
            isExternal 
            display="flex" 
            alignItems="center" 
            justifyContent="center"
            flex="1"
          >
            <svg 
              width="20" 
              height="20" 
              viewBox="0 0 58 58" 
              fill="none" 
              xmlns="http://www.w3.org/2000/svg"
              className="spin"
            >
              <mask id="mask0_1219_43364" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="58" height="58">
                <circle cx="29.3574" cy="29" r="28.0918" fill="#C4C4C4" stroke="black" />
              </mask>
              <g mask="url(#mask0_1219_43364)">
                <circle cx="15.7553" cy="-4.66944" r="23.7214" transform="rotate(-22 15.7553 -4.66944)" fill="#5D0E41" />
                <circle cx="42.9623" cy="62.6685" r="23.7214" transform="rotate(-22 42.9623 62.6685)" fill="#5D0E41" />
                <circle cx="-4.31014" cy="42.603" r="23.7214" transform="rotate(-22 -4.31014 42.603)" fill="#FF204E" />
                <circle cx="62.4037" cy="13.8545" r="23.7214" transform="rotate(-22 62.4037 13.8545)" fill="#FF204E" />
              </g>
            </svg>
            <Text as="span" fontWeight="bold" ml={2}>
              Texto promocional editable 🥳🎉
            </Text>
          </Link>
          <CloseButton onClick={handleCloseSubnavbar} />

          <Confetti
            width={window.innerWidth}
            height={40}
            numberOfPieces={50}
            gravity={0.2}
            recycle={true}
            initialVelocityX={0}
            initialVelocityY={5}
            confettiSource={{ x: 0, y: 0, w: window.innerWidth, h: 10 }}
          />
        </Box>
      )}

      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay>
          <DrawerContent>
            <DrawerHeader borderBottomWidth="1px">
              <Flex justifyContent="center" alignItems="center">
                <Box as="span" display="inline-block" mr="4">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" style={{ height: '30px' }}>
                    <path
                      fill="#63E6BE"
                      d="M32 419L0 479.2l.8-328C.8 85.3 54 32 120 32h327.2c-93 28.9-189.9 94.2-253.9 168.6C122.7 282 82.6 338 32 419M448 32S305.2 98.8 261.6 199.1c-23.2 53.6-28.9 118.1-71 158.6-28.9 27.8-69.8 38.2-105.3 56.3-23.2 12-66.4 40.5-84.9 66h328.4c66 0 119.3-53.3 119.3-119.2-.1 0-.1-328.8-.1-328.8z"
                    />
                  </svg>
                </Box>
                <Text fontSize="2xl" fontFamily="monospace" fontWeight="bold">
                  Vefixy
                </Text>
              </Flex>
              <CloseButton position="absolute" top="8px" right="8px" onClick={onClose} />
            </DrawerHeader>
            <DrawerBody>
              <VStack as={'nav'} spacing={4}>
                {Links.map((link, index) => (
                  <NavLink key={`drawer-link-${index}`}>{link}</NavLink>
                ))}
              </VStack>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>

      <Box pt="16">{children}</Box>
    </>
  );
}