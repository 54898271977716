import React from 'react';
import {
  Box,
  Container,
  Stack,
  Text,
  Flex,
  useColorModeValue,
  Link,
  chakra,
  VisuallyHidden,
} from '@chakra-ui/react';
import { FaXTwitter } from 'react-icons/fa6';
import { FaLinkedinIn, FaInstagram } from 'react-icons/fa';

const SocialButton = ({ children, label, href }) => {
  return (
    <chakra.button
      bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
      rounded={'full'}
      w={8}
      h={8}
      cursor={'pointer'}
      as={'a'}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
      }}
      mx="4px"
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <Box
      bg="inherit" // Elimina el color de fondo para heredar el fondo de la página
      color={useColorModeValue('gray.700', 'gray.200')}
    >
      <Container as={Stack} maxW={'6xl'} py={1} />
      <Box pb={6}>
        <Flex
          align={'center'}
          _before={{
            content: '""',
            borderBottom: '1px solid',
            borderColor: useColorModeValue('gray.200', 'gray.700'),
            flexGrow: 1,
            mr: 8,
          }}
          _after={{
            content: '""',
            borderBottom: '1px solid',
            borderColor: useColorModeValue('gray.200', 'gray.700'),
            flexGrow: 1,
            ml: 8,
          }}
        >
          <Link href="https://pharmabro.com/" isExternal>
            <img
              src="/images/pharmabro/LogoPBNegro.png"
              alt="PharmaBro Logo"
              width="32"
              height="32"
            />
          </Link>
        </Flex>
        <Text pt={4} fontSize={'sm'} textAlign={'center'}>
          <Text as="span" fontWeight="bold">
            © {currentYear}{' '}
            <Link href="https://pharmabro.com/" isExternal fontWeight="bold">
              PharmaBro
            </Link>
            .
          </Text>
          <br />
          Todos los derechos reservados.
        </Text>
        <Flex justifyContent="center" mt={2}>
          <SocialButton label={'Twitter'} href={'https://x.com/'}>
            <FaXTwitter />
          </SocialButton>
          <SocialButton label={'LinkedIn'} href={'https://www.linkedin.com/'}>
            <FaLinkedinIn />
          </SocialButton>
          <SocialButton label={'Instagram'} href={'https://www.instagram.com/'}>
            <FaInstagram />
          </SocialButton>
        </Flex>
        <Flex justify="center" mt={5}>
            <Flex align="center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                width="20"
                height="20"
                style={{ marginRight: '4px' }}
              >
                <path
                  fill="#63E6BE"
                  d="M32 419L0 479.2l.8-328C.8 85.3 54 32 120 32h327.2c-93 28.9-189.9 94.2-253.9 168.6C122.7 282 82.6 338 32 419M448 32S305.2 98.8 261.6 199.1c-23.2 53.6-28.9 118.1-71 158.6-28.9 27.8-69.8 38.2-105.3 56.3-23.2 12-66.4 40.5-84.9 66h328.4c66 0 119.3-53.3 119.3-119.2-.1 0-.1-328.8-.1-328.8z"
                />
              </svg>
              <Link href="https://vefixy.com/" isExternal fontWeight="bold">
                Desarrollado por Vefixy
              </Link>
            </Flex>
          </Flex>
      </Box>
    </Box>
  );
}