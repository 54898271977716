// apiRoutes.js (o como se llame tu archivo de rutas)
const baseurl = 'https://pharmabro.vefixy.com/api/';

// RUTAS GET y POST
const routes = {
  // AUTH
  isActive: 'auth/isActive',
  userRegister: 'auth/register',
  userLogin: 'auth/login',
  userInfo: 'auth/userinfo',
  exitSession: 'auth/exit',
  getAllUsers: 'auth/users',
  
  // CHANGE DATA
  changeProfileImage: 'change-data/changeProfileImage',
  changePassword: 'change-data/changePassword',
  updateUserData: 'change-data/updateUserData',
  updateSendNotifications: 'change-data/updateSendNotifications',
  disabledAccount: 'change-data/disabledAccount',
  
  // STOCK
  addProduct: 'stock/add',
  deleteProduct: 'stock/delete',
  editProduct: 'stock/edit',
  getAllProducts: 'stock/all',

};

// Función para obtener el token JWT del localStorage
const getToken = () => {
  return localStorage.getItem('token'); // O cambiar a sessionStorage si prefieres
};

// Función para realizar solicitudes GET con el token JWT si está disponible
const getWithAuth = async (route) => {
  const token = getToken();
  
  const response = await fetch(`${baseurl}${route}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...(token && { 'Authorization': `Bearer ${token}` }) // Incluir el token si existe
    }
  });
  
  return response.json(); // Puedes manejar el parsing de la respuesta
};

// Función ajustada para realizar solicitudes PUT, POST, y DELETE con el token JWT si está disponible
const requestWithAuth = async (route, data, method = 'POST') => {
  const token = getToken();

  const response = await fetch(`${baseurl}${route}`, {
    method, // Utiliza el método que se pasa como parámetro (POST, PUT, DELETE, etc.)
    headers: {
      'Content-Type': 'application/json',
      ...(token && { 'Authorization': `Bearer ${token}` }) // Incluir el token si existe
    },
    body: JSON.stringify(data),
  });

  // Retorna la respuesta completa para acceder al status y al JSON
  const responseData = await response.json();
  return { status: response.status, data: responseData };
};

module.exports = {
  baseurl,
  routes,
  getWithAuth,
  requestWithAuth, // Exporta la función ajustada
};