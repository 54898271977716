import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Input,
  FormControl,
  FormLabel,
  useDisclosure,
  useToast,
  Avatar,
  Flex,
  Tooltip,
  Icon,
} from '@chakra-ui/react';
import { IoMdAdd } from 'react-icons/io';
import { FaCircleInfo } from 'react-icons/fa6'; // Importar icono de información
import { requestWithAuth, routes } from '../../utils/apiRoutes';

export default function AddProduct() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [descripcion, setDescripcion] = useState('');
  const [codbarras, setCodbarras] = useState('');
  const [precio, setPrecio] = useState('');
  const [zonastock, setZonaStock] = useState('');
  const [stock, setStock] = useState('');
  const [img, setImg] = useState('');
  const toast = useToast();

  const handleAddProduct = async () => {
    const newProduct = {
      descripcion,
      codbarras,
      precio: parseFloat(precio),
      zonastock,
      stock: parseInt(stock, 10),
      img,
    };

    try {
      const response = await requestWithAuth(routes.addProduct, newProduct);
      if (response.status === 201) {
        toast({
          title: 'Producto agregado',
          description: 'El producto se ha agregado exitosamente.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        onClose();
      } else {
        toast({
          title: 'Error al agregar producto',
          description: 'Hubo un problema al agregar el producto.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Error en la solicitud',
        description: 'No se pudo agregar el producto. Inténtalo nuevamente.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Button
        leftIcon={<IoMdAdd />}
        colorScheme="gray"
        variant="solid"
        onClick={onOpen}
      >
        Agregar producto
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Agregar nuevo producto</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Descripción */}
            <FormControl mb={4}>
              <Flex align="center">
                <FormLabel>Descripción</FormLabel>
                <Tooltip label="Ejemplo: Televisor Samsung 55 pulgadas" placement="right">
                  <span>
                    <Icon as={FaCircleInfo} color="gray.400" ml={2} />
                  </span>
                </Tooltip>
              </Flex>
              <Input
                value={descripcion}
                onChange={(e) => setDescripcion(e.target.value)}
                placeholder="Descripción del producto"
              />
            </FormControl>

            {/* Código de barras */}
            <FormControl mb={4}>
              <Flex align="center">
                <FormLabel>Código de barras</FormLabel>
                <Tooltip label="Ejemplo: 1234567890123" placement="right">
                  <span>
                    <Icon as={FaCircleInfo} color="gray.400" ml={2} />
                  </span>
                </Tooltip>
              </Flex>
              <Input
                value={codbarras}
                onChange={(e) => setCodbarras(e.target.value)}
                placeholder="Código de barras"
              />
            </FormControl>

            {/* Precio */}
            <FormControl mb={4}>
              <Flex align="center">
                <FormLabel>Precio</FormLabel>
                <Tooltip label="Ejemplo: 199.99" placement="right">
                  <span>
                    <Icon as={FaCircleInfo} color="gray.400" ml={2} />
                  </span>
                </Tooltip>
              </Flex>
              <Input
                type="number"
                value={precio}
                onChange={(e) => setPrecio(e.target.value)}
                placeholder="Precio"
              />
            </FormControl>

            {/* Zona de stock */}
            <FormControl mb={4}>
              <Flex align="center">
                <FormLabel>Zona de stock</FormLabel>
                <Tooltip label="Ejemplo: Monterey" placement="right">
                  <span>
                    <Icon as={FaCircleInfo} color="gray.400" ml={2} />
                  </span>
                </Tooltip>
              </Flex>
              <Input
                value={zonastock}
                onChange={(e) => setZonaStock(e.target.value)}
                placeholder="Zona de stock"
              />
            </FormControl>

            {/* Cantidad en stock */}
            <FormControl mb={4}>
              <Flex align="center">
                <FormLabel>Cantidad en stock</FormLabel>
                <Tooltip label="Ejemplo: 50" placement="right">
                  <span>
                    <Icon as={FaCircleInfo} color="gray.400" ml={2} />
                  </span>
                </Tooltip>
              </Flex>
              <Input
                type="number"
                value={stock}
                onChange={(e) => setStock(e.target.value)}
                placeholder="Cantidad en stock"
              />
            </FormControl>

            {/* URL de la imagen */}
            <FormControl mb={4}>
              <Flex align="center">
                <FormLabel>URL de la imagen</FormLabel>
                <Tooltip label="Ejemplo: https://example.com/image.jpg" placement="right">
                  <span>
                    <Icon as={FaCircleInfo} color="gray.400" ml={2} />
                  </span>
                </Tooltip>
              </Flex>
              <Input
                value={img}
                onChange={(e) => setImg(e.target.value)}
                placeholder="URL de la imagen"
              />
            </FormControl>

            {/* Avatar para previsualización de la imagen */}
            <Flex justify="center" mt={4} mb={4}>
              <Avatar
                size="xl"
                src={img}
                name={descripcion}
              />
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="teal" onClick={handleAddProduct}>
              Agregar producto
            </Button>
            <Button variant="ghost" ml={3} onClick={onClose}>
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}